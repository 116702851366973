<template>
  <div class="clearfix">
    <!-- Full Name Input -->
     
    <!-- v-validate="'required|alpha_dash|min:3'" -->
    <vs-input
      data-vv-validate-on="blur"
      label-placeholder="FullNameAr"
      name="FullNameAr"
      placeholder="Name"
      v-model="FullNameAr"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("FullNameAr") }}</span>

    <!-- Email Input -->
    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="Email"
      type="Email"
      label-placeholder="Email"
      placeholder="Email"
      v-model="Email"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("Email") }}</span>

    <!-- Phone Number Input -->
    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="PhoneNumber"
      type="number"
      label-placeholder="Phone Number"
      placeholder="Phone Number"
      v-model="PhoneNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("PhoneNumber") }}</span>

    <!-- Address Input -->
    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="AddressAR"
      type="text"
      label-placeholder="Address"
      placeholder="Address"
      v-model="AddressAR"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("AddressAR") }}</span>

    <!-- Password Input -->
    <div class="relative">
      <vs-input
        ref="Password"
        :type="showPassword ? 'text' : 'password'"
        data-vv-validate-on="blur"
        v-validate="'required|min:6|max:10'"
        name="Password"
        label-placeholder="Password"
        placeholder="Password"
        v-model="Password"
        class="w-full mt-6"
      />
      <i
        class="feather"
        :class="showPassword ? 'icon-eye-off' : 'icon-eye'"
        @click="togglePasswordVisibility"
        style="cursor: pointer; position: absolute; right: 15px; top: 15px;"
      ></i>
    <span class="text-danger text-sm">{{ errors.first("Password") }}</span>
    </div>

    <!-- Confirm Password Input -->
    <div class="relative">
      <vs-input
        :type="showPassword ? 'text' : 'password'"
        v-validate="'min:6|max:10|confirmed:Password'"
        data-vv-validate-on="blur"
        data-vv-as="Password"
        name="confirm_password"
        label-placeholder="Confirm Password"
        placeholder="Confirm Password"
        v-model="confirm_password"
        class="w-full mt-6"
      />

    <span class="text-danger text-sm">{{ errors.first("confirm_password") }}</span>
    </div>

    <!-- Terms and Conditions Checkbox -->
    <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">{{ $t('termsConditions') }}</vs-checkbox>

    <!-- Login and Register Buttons -->
    <vs-button type="border" to="/pages/login" class="mt-6">Login</vs-button>
    <vs-button
      class="float-right mt-6"
      @click="registerUserJWt"
      :disabled="!validateForm"
      >Register</vs-button
    >
  </div>
</template>

<script>
import moduleAuth from "@/store/auth/moduleAuth.js";
export default {
  data() {
    return {
      FullNameAr: "",
      Email: "",
      PhoneNumber:"",
      UserType:"موظف",
      AddressAR:"",
      Password: "",
      showPassword:false,
      confirm_password: "",
      isTermsConditionAccepted: true
    };
  },
  computed: {
    // RelodePage(){
    //   return window.location.reload();
    // },
    validateForm() {
      return (
        !this.errors.any() &&
        this.FullNameAr != "" &&
        this.Email != "" &&
        this.AddressAR !=""&&
        this.Password != "" &&
        this.confirm_password != "" &&
        this.isTermsConditionAccepted === true
      );
    }
  },
   activated() {
     window.location.reload();
  },
  created() {

    if (!moduleAuth.isRegistered) {
      this.$store.registerModule("_auth1", moduleAuth);
      moduleAuth.isRegistered = true;
    }
    this.Email="";
    this.Password="";
    this.AddressAR="";

    //this.GetCountries();

  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state._auth1.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: this.$t("LoginAttempt"),
          text: this.$t("Youarealreadyloggedin"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });

        return false;
      }
      return true;
    },
    togglePasswordVisibility(){
      this.showPassword= !this.showPassword;
    },
  registerUserJWt() {
  // If form is not validated or user is already logged in, return
  if (!this.validateForm || !this.checkLogin()) return;

  const payload = {
    userDetails: {
      FullNameAr: this.FullNameAr,
      PhoneNumber: this.PhoneNumber,
      AdressAR: this.AddressAR,
      UserType: this.UserType,
      Email: this.Email,
      Password: this.Password,
    }
  };

  console.log('Dispatching registerUserJWT action'); // Log before dispatch

  this.$store.dispatch("_auth1/registerUserJWT", payload.userDetails)
    .then(response => {
      // Log the response after the dispatch
      if(response.data.statuscode==400)
      {
        this.$vs.notify({ time:8000,
        color: "danger",
        title: "لم يتم تسجيل الدخول",
        text:response.data.message,
      });
      }

else
{

this.$vs.notify({ time:8000,
        color: "success",
        title: "تم تسجيل الدخول",
        text:response.data.message,
      });
}

    })
    .catch(error => {
   console.log('error Response:', error);
    });
}



  }
};
</script>
