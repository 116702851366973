<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row no-gutter">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                          <img :src="defaultProfilePic" style="width:90%; height: 600px;padding-top: 50px;" alt="login" class="mx-auto">                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                            <div class="px-8 pt-8 register-tabs-container">
                                <div class="vx-card__title mb-4">
                                    <h4 class="mb-4">{{$t('Register')}}</h4>
                                    <p>{{$t('FillTheBelowFormToCreateANewAccount')}}.</p>
                                </div>
                                <vs-tabs>
                                  <vs-tab label="تسجيل">
                                    <register-jwt></register-jwt>
                                  </vs-tab>

                                  <!-- <vs-tab label="Firebase">
                                    <register-firebase></register-firebase>
                                  </vs-tab> -->

                                  <!-- <vs-tab label="Auth0">
                                    <register-auth0></register-auth0>
                                  </vs-tab> -->
                                </vs-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import RegisterJwt from "./RegisterJWT.vue"
// import RegisterFirebase from "./RegisterFirebase.vue"
// import RegisterAuth0 from "./RegisterAuth0.vue"

export default {
  computed:{
    defaultProfilePic() {
      if (window.location.hostname.includes("clixy.net")) {
        return require("@/assets/images/clixy.png");
      } else if (window.location.hostname.includes("ezzeyfind.com")) {
        return require("@/assets/images/ezzyFindLogo.jpg");
      } else {
        return require("@/assets/images/clixy.png");
      }
    }
  },
    components: {
      RegisterJwt,
      // RegisterFirebase,
      // RegisterAuth0
    }
}
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
